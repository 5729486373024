/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetAllTxs } from 'api';
import { queryKeys } from 'api/queryKeys';
import type {
    AllTxsResponse,
    CustomQueryResult,
    ExtraConditions,
} from 'api/types';

export const useGetAllTxsQuery = (
    page: number,
    limit: number,
    address?: string,
    tables?: Array<string>,
    startDate?: number,
    endDate?: number,
    extraConditions?: ExtraConditions,
): CustomQueryResult<AllTxsResponse> => {
    const { data, refetch, isLoading, error, ...rest } = useQuery({
        queryKey: [queryKeys.ALL_TXS, limit, address, tables, page],
        queryFn: async () => {
            const response = await apiGetAllTxs(
                page,
                limit,
                address,
                tables,
                startDate,
                endDate,
                extraConditions,
            );
            return {
                data: response.data.data,
                count: response.data.count,
                next: response.data.next,
            };
        },
        gcTime: 5 * 60 * 1000,
        staleTime: 30 * 1000,
        retry: 2,
    });

    return {
        data: data ?? {
            data: [],
            count: 0,
            next: false,
        },
        isLoading,
        error,
        refetch,
        ...rest,
    };
};
