/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    CalendarOutlined,
    DownloadOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {
    Alert,
    Button,
    DatePicker,
    Divider,
    Input,
    List,
    Row,
    Select,
    Skeleton,
    Space,
    Tag,
    Typography,
} from 'antd';
import type { SelectProps } from 'antd';
import { apiGetAllTxs, useGetAllTxsQuery, useGetTokensQuery } from 'api';
import type { ExtraConditions, HistoryTransaction } from 'api/types';
import { Sidebar, TransactionItem } from 'components';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSetIsSidebarOpen } from 'store/SidebarStore';
import type { $MixedElement } from 'types';
import { PAGE_SIZE, txTypeOptions, typeToTable } from 'utils/transactions';
import { parseUnits } from 'viem';

export const Transactions = (): $MixedElement => {
    const setIsSideBarOpen = useSetIsSidebarOpen();
    useEffect(() => {
        setIsSideBarOpen(false);
    }, []);

    const [page, setPage] = useState(0);
    const [transactions, setTransactions] = useState<Array<HistoryTransaction>>(
        [],
    );
    const [hasMore, setHasMore] = useState(true);

    // Form states
    const [selectedTypes, setSelectedTypes] = useState<Array<string>>([]);
    const [address, setAddress] = useState<string>('');
    const [selectedDateRange, setSelectedDateRange] = useState<
        [number?, number?]
    >([]);
    const [amount, setAmount] = useState<string>('');
    const [formattedAmount, setFormattedAmount] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [isExporting, setIsExporting] = useState(false);

    // Applied filter states
    const [appliedTypes, setAppliedTypes] = useState<Array<string> | null>(
        null,
    );
    const [appliedAddress, setAppliedAddress] = useState<string | null>(null);
    const [appliedDateRange, setAppliedDateRange] = useState<
        [number?, number?]
    >([]);
    const [appliedExtraConditions, setAppliedExtraConditions] =
        useState<ExtraConditions | null>(null);

    // queries
    const { data: tokens } = useGetTokensQuery();

    const {
        data: txs,
        isPending,
        error,
        refetch,
        isFetching,
    } = useGetAllTxsQuery(
        page,
        PAGE_SIZE,
        appliedAddress || undefined,
        appliedTypes?.map(typeToTable) || undefined,
        appliedDateRange[0],
        appliedDateRange[1],
        appliedExtraConditions || undefined,
    );

    useEffect(() => {
        setTransactions([]);
        setPage(0);
        refetch();
    }, [appliedTypes, appliedAddress, appliedDateRange]);

    const handleApplyFilters = (): void => {
        setAppliedTypes(selectedTypes.length === 0 ? null : selectedTypes);
        setAppliedAddress(address === '' ? null : address);
        setAppliedDateRange(
            selectedDateRange.map((timestamp) =>
                timestamp ? Math.floor(timestamp / 1000) : undefined,
            ) as [number?, number?],
        );

        const extraConditions: ExtraConditions = {};

        // Handle investment type conditions
        if (selectedTypes.includes('invest_deposit')) {
            extraConditions['hi.type'] = 'deposit';
        } else if (selectedTypes.includes('invest_withdraw')) {
            extraConditions['hi.type'] = 'withdraw';
        }

        if (token) {
            const tokenAddressFields = [
                'hi.token_address',
                'hp.token_address',
                'htt.token_address',
                'hs.input_token_address',
                'hs.output_token_address',
            ] as const;

            tokenAddressFields.forEach((field) => {
                extraConditions[field] = token;
            });

            if (formattedAmount) {
                const amountFields = [
                    'hi.amount',
                    'hp.amount',
                    'htt.amount',
                ] as const;

                amountFields.forEach((field) => {
                    extraConditions[field] = formattedAmount;
                });
            }
        }

        setAppliedExtraConditions(extraConditions);
    };

    const handleClearFilters = (): void => {
        setSelectedTypes([]);
        setAddress('');
        setSelectedDateRange([]);
        setAppliedTypes(null);
        setAppliedAddress(null);
        setAppliedDateRange([]);
    };

    useEffect(() => {
        if (txs?.data) {
            const newTxs = txs.data.filter(
                (newTx) =>
                    !transactions.some(
                        (existingTx) => existingTx.hash === newTx.hash,
                    ),
            );

            if (newTxs.length > 0) {
                setTransactions((prev) => [...prev, ...newTxs]);
            }

            setHasMore(txs.next || isFetching);
        }
    }, [txs?.data]);

    const loadMoreData = (): void => {
        if (isFetching || !hasMore) return;
        setPage((prevPage) => prevPage + 1);
    };

    const handleExportTransactions = async (): Promise<void> => {
        setIsExporting(true);
        try {
            const response = await apiGetAllTxs(
                0,
                50000,
                appliedAddress || undefined,
                appliedTypes?.map(typeToTable) || undefined,
                appliedDateRange[0],
                appliedDateRange[1],
            );

            const txData = response.data.data;
            const csvContent = [
                [
                    'Type',
                    'Subtype',
                    'Related Address 1',
                    'Related Address 2',
                    'Token 1',
                    'Token 2',
                    'Amount 1',
                    'Amount 2',
                    'USD Value 1',
                    'USD Value 2',
                    'Protocol',
                    'Transfer Type',
                    'Date',
                ].join(','),
                ...txData.map((tx) => {
                    const claveData = tx.clave_data?.[0];
                    if (!claveData) {
                        return [
                            'unknown',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            new Date(tx.timestamp * 1000).toISOString(),
                        ].join(',');
                    }

                    const type = claveData.type;
                    let subtype = '';
                    let addr1 = '';
                    let addr2 = '';
                    let token1 = '';
                    let token2 = '';
                    let amount1 = '';
                    let amount2 = '';
                    let usdValue1 = '';
                    let usdValue2 = '';
                    let protocol = '';
                    let transferType = '';
                    switch (claveData.type) {
                        case 'transfer': {
                            const { sender, recipient, amount, tokenInfo } =
                                claveData.payload;
                            addr1 = sender.address;
                            addr2 = recipient.address;
                            token1 = tokenInfo.symbol;
                            amount1 = (
                                Number(amount) /
                                Math.pow(10, tokenInfo.decimals)
                            ).toString();
                            usdValue1 = (
                                Number(amount1) * tokenInfo.usdPrice
                            ).toString();
                            transferType =
                                recipient.id && sender.id
                                    ? 'clave_to_clave'
                                    : sender.id
                                    ? 'clave_to_external'
                                    : 'external_to_clave';

                            break;
                        }
                        case 'swap': {
                            const {
                                sender,
                                inputAmount,
                                outputAmount,
                                inputTokenInfo,
                                outputTokenInfo,
                            } = claveData.payload;
                            addr1 = sender.address;
                            token1 = inputTokenInfo.symbol;
                            token2 = outputTokenInfo.symbol;
                            amount1 = (
                                Number(inputAmount) /
                                Math.pow(10, inputTokenInfo.decimals)
                            ).toString();
                            amount2 = (
                                Number(outputAmount) /
                                Math.pow(10, outputTokenInfo.decimals)
                            ).toString();
                            usdValue1 = (
                                Number(amount1) * inputTokenInfo.usdPrice
                            ).toString();
                            usdValue2 = (
                                Number(amount2) * outputTokenInfo.usdPrice
                            ).toString();
                            break;
                        }
                        case 'invest': {
                            const {
                                sender,
                                amount,
                                tokenInfo,
                                protocol: protocolName,
                                type: investType,
                            } = claveData.payload;
                            addr1 = sender.address;
                            token1 = tokenInfo.symbol;
                            amount1 = (
                                Number(amount) /
                                Math.pow(10, tokenInfo.decimals)
                            ).toString();
                            usdValue1 = (
                                Number(amount1) * tokenInfo.usdPrice
                            ).toString();
                            protocol = protocolName;
                            subtype = investType.toLowerCase();
                            break;
                        }
                        case 'layerswap': {
                            const {
                                userAddress,
                                amount,
                                tokenInfo,
                                type: layerswapType,
                            } = claveData.payload;
                            addr1 = userAddress.address;
                            token1 = tokenInfo.symbol;
                            amount1 = (
                                Number(amount) /
                                Math.pow(10, tokenInfo.decimals)
                            ).toString();
                            usdValue1 = (
                                Number(amount1) * tokenInfo.usdPrice
                            ).toString();
                            subtype = layerswapType.toLowerCase();
                            break;
                        }
                        case 'guardian_change': {
                            const { wallet, guardian, operation } =
                                claveData.payload;
                            addr1 = wallet.address;
                            addr2 = guardian.address;
                            subtype = operation.toLowerCase();
                            break;
                        }
                        case 'offramp':
                        case 'onramp': {
                            const { userAddress, amount, tokenInfo } =
                                claveData.payload;
                            addr1 = userAddress.address;
                            token1 = tokenInfo.symbol;
                            amount1 = (
                                Number(amount) /
                                Math.pow(10, tokenInfo.decimals)
                            ).toString();
                            usdValue1 = (
                                Number(amount1) * tokenInfo.usdPrice
                            ).toString();
                            break;
                        }
                        case 'peanut_deposit':
                        case 'peanut_withdraw': {
                            const address =
                                claveData.type === 'peanut_deposit'
                                    ? claveData.payload.depositAddress
                                    : claveData.payload.withdrawAddress;
                            const { amount, tokenInfo } = claveData.payload;
                            addr1 = address.address;
                            token1 = tokenInfo.symbol;
                            amount1 = (
                                Number(amount) /
                                Math.pow(10, tokenInfo.decimals)
                            ).toString();
                            usdValue1 = (
                                Number(amount1) * tokenInfo.usdPrice
                            ).toString();
                            break;
                        }
                        case 'referral': {
                            const { referred, referrer } = claveData.payload;
                            addr1 = referred.address;
                            addr2 = referrer;
                            break;
                        }
                    }

                    return [
                        type,
                        subtype || '',
                        addr1 || '',
                        addr2 || '',
                        token1 || '',
                        token2 || '',
                        amount1 || '',
                        amount2 || '',
                        usdValue1 || '',
                        usdValue2 || '',
                        protocol || '',
                        transferType || '',
                        new Date(tx.timestamp * 1000).toISOString(),
                    ].join(',');
                }),
            ].join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `clave-transactions-${new Date().toISOString()}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Export failed:', error);
        } finally {
            setIsExporting(false);
        }
    };

    if (error) {
        return (
            <Alert
                message="Error"
                description="Failed to load transactions. Please try again later."
                type="error"
                action={<button onClick={() => refetch()}>Retry</button>}
            />
        );
    }

    return (
        <Row wrap={false}>
            <Sidebar />
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        padding: '24px 32px 0',
                        backgroundColor: '#f5f5f5',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        borderBottom: '1px solid #f0f0f0',
                    }}
                >
                    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                        <Typography.Title
                            level={2}
                            style={{ marginBottom: '24px' }}
                        >
                            Transactions
                        </Typography.Title>
                        <div style={filterSectionStyles}>
                            <Space
                                direction="vertical"
                                size={16}
                                style={{ width: '100%' }}
                            >
                                <Space
                                    wrap
                                    style={{ width: '100%', gap: '12px' }}
                                >
                                    <Select
                                        mode="multiple"
                                        tagRender={tagRender}
                                        style={{
                                            minWidth: '280px',
                                            flex: 2,
                                            ...commonInputStyles,
                                        }}
                                        placeholder="Filter by transaction type"
                                        options={txTypeOptions(selectedTypes)}
                                        value={selectedTypes}
                                        onChange={setSelectedTypes}
                                        maxTagCount="responsive"
                                        allowClear
                                        dropdownStyle={{
                                            padding: '12px',
                                            borderRadius: '8px',
                                            boxShadow:
                                                '0 3px 6px rgba(0,0,0,0.1)',
                                        }}
                                        optionRender={(option) => (
                                            <Space>
                                                <Tag
                                                    color={option.data.color}
                                                    style={{
                                                        padding: '6px 12px',
                                                        fontSize: '14px',
                                                        borderRadius: '6px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {option.data.label}
                                                </Tag>
                                            </Space>
                                        )}
                                    />
                                    <DatePicker.RangePicker
                                        style={{
                                            minWidth: '280px',
                                            flex: 2,
                                            ...commonInputStyles,
                                        }}
                                        onChange={(dates) => {
                                            if (dates) {
                                                setSelectedDateRange([
                                                    dates[0]?.valueOf(),
                                                    dates[1]?.valueOf(),
                                                ]);
                                            } else {
                                                setSelectedDateRange([]);
                                            }
                                        }}
                                        allowClear
                                        allowEmpty
                                        placeholder={['Start date', 'End date']}
                                        format="MMM D, YYYY"
                                    />
                                    <Input
                                        placeholder="Search by address"
                                        value={address}
                                        onChange={(e) =>
                                            setAddress(e.target.value)
                                        }
                                        prefix={
                                            <SearchOutlined
                                                style={{ color: '#bfbfbf' }}
                                            />
                                        }
                                        allowClear
                                        style={{
                                            minWidth: '280px',
                                            flex: 2,
                                            ...commonInputStyles,
                                        }}
                                    />
                                    <Select
                                        showSearch
                                        placeholder="Select token"
                                        value={token || undefined}
                                        onChange={setToken}
                                        style={{
                                            minWidth: '200px',
                                            flex: 1,
                                            ...commonInputStyles,
                                        }}
                                        allowClear
                                        optionFilterProp="label"
                                    >
                                        {tokens?.map((token) => (
                                            <Select.Option
                                                key={token.address}
                                                value={token.address}
                                                label={token.symbol}
                                            >
                                                <Space>
                                                    <span>{token.symbol}</span>
                                                    <Typography.Text
                                                        type="secondary"
                                                        style={{
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        ({token.name})
                                                    </Typography.Text>
                                                </Space>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    {token && (
                                        <Input
                                            placeholder="Amount"
                                            value={amount}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (
                                                    !value ||
                                                    /^\d*\.?\d*$/.test(value)
                                                ) {
                                                    setAmount(value);
                                                    const selectedToken =
                                                        tokens?.find(
                                                            (t) =>
                                                                t.address ===
                                                                token,
                                                        );

                                                    if (selectedToken) {
                                                        setFormattedAmount(
                                                            parseUnits(
                                                                value,
                                                                selectedToken.decimals,
                                                            ).toString(),
                                                        );
                                                    } else {
                                                        setFormattedAmount(
                                                            value,
                                                        );
                                                    }
                                                }
                                            }}
                                            allowClear
                                            style={{
                                                minWidth: '150px',
                                                flex: 1,
                                                ...commonInputStyles,
                                            }}
                                        />
                                    )}
                                    <Button
                                        type="primary"
                                        onClick={handleApplyFilters}
                                        style={{
                                            ...commonInputStyles,
                                            minWidth: '120px',
                                            fontWeight: 500,
                                        }}
                                        loading={isFetching}
                                    >
                                        Apply Filters
                                    </Button>
                                    <Button
                                        icon={<DownloadOutlined />}
                                        onClick={handleExportTransactions}
                                        style={{
                                            ...commonInputStyles,
                                            minWidth: '120px',
                                            fontWeight: 500,
                                        }}
                                        loading={isExporting}
                                    >
                                        Export
                                    </Button>
                                </Space>
                                {((appliedTypes?.length || 0) > 0 ||
                                    appliedAddress ||
                                    appliedDateRange[0] ||
                                    appliedDateRange[1]) && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            flexWrap: 'wrap',
                                            padding: '12px',
                                            backgroundColor: '#f5f5f5',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Typography.Text
                                            type="secondary"
                                            style={{ marginRight: '4px' }}
                                        >
                                            Active filters:
                                        </Typography.Text>
                                        {appliedTypes?.map((type) => {
                                            const option = txTypeOptions(
                                                selectedTypes,
                                            )?.find(
                                                (opt) => opt.value === type,
                                            );
                                            return (
                                                <Tag
                                                    key={type}
                                                    color={option?.color}
                                                    style={
                                                        activeFilterTagStyles
                                                    }
                                                >
                                                    {option?.label}
                                                </Tag>
                                            );
                                        })}
                                        {appliedAddress && (
                                            <Tag style={activeFilterTagStyles}>
                                                <SearchOutlined
                                                    style={{ fontSize: '12px' }}
                                                />
                                                {appliedAddress}
                                            </Tag>
                                        )}
                                        {(appliedDateRange[0] ||
                                            appliedDateRange[1]) && (
                                            <Tag style={activeFilterTagStyles}>
                                                <CalendarOutlined
                                                    style={{
                                                        fontSize: '12px',
                                                        marginRight: '4px',
                                                    }}
                                                />
                                                {appliedDateRange[0]
                                                    ? new Date(
                                                          appliedDateRange[0] *
                                                              1000,
                                                      ).toLocaleDateString(
                                                          'en-US',
                                                          {
                                                              month: 'short',
                                                              day: 'numeric',
                                                              year: 'numeric',
                                                          },
                                                      )
                                                    : 'Start'}
                                                {' - '}
                                                {appliedDateRange[1]
                                                    ? new Date(
                                                          appliedDateRange[1] *
                                                              1000,
                                                      ).toLocaleDateString(
                                                          'en-US',
                                                          {
                                                              month: 'short',
                                                              day: 'numeric',
                                                              year: 'numeric',
                                                          },
                                                      )
                                                    : 'Now'}
                                            </Tag>
                                        )}
                                        <Button
                                            type="link"
                                            size="small"
                                            onClick={handleClearFilters}
                                            style={{ marginLeft: 'auto' }}
                                        >
                                            Clear all
                                        </Button>
                                    </div>
                                )}
                            </Space>
                        </div>
                    </div>
                </div>
                <div
                    id="scrollableDiv"
                    style={{
                        height: 'calc(100vh - 140px)',
                        overflow: 'auto',
                        padding: '0 32px 24px',
                        backgroundColor: '#f5f5f5',
                    }}
                >
                    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                        {(isPending && transactions.length === 0) ||
                        (txs?.data?.length > 0 && transactions.length === 0) ? (
                            <div style={{ padding: '20px 0' }}>
                                <Skeleton active paragraph={{ rows: 4 }} />
                            </div>
                        ) : (
                            <InfiniteScroll
                                dataLength={transactions.length}
                                next={loadMoreData}
                                hasMore={hasMore}
                                loader={
                                    <div style={{ padding: '20px 0' }}>
                                        <Skeleton
                                            active
                                            loading={isPending}
                                            paragraph={{ rows: 2 }}
                                        />
                                    </div>
                                }
                                endMessage={
                                    <Divider plain>
                                        No more transactions to load
                                    </Divider>
                                }
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    dataSource={transactions.filter(
                                        (tx, index, self) =>
                                            index ===
                                            self.findIndex(
                                                (t) => t.hash === tx.hash,
                                            ),
                                    )}
                                    renderItem={(item) => (
                                        <TransactionItem
                                            key={item.hash}
                                            transaction={item}
                                        />
                                    )}
                                />
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            </div>
        </Row>
    );
};

const tagRender: SelectProps['tagRender'] = (props) => {
    const { label, value, closable, onClose } = props;
    const option = txTypeOptions([])?.find((opt) => opt.value === value);

    return (
        <Tag
            color={option?.color}
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 4,
                padding: '4px 8px',
                fontSize: '13px',
                borderRadius: '6px',
            }}
        >
            {label}
        </Tag>
    );
};

const commonInputStyles = {
    height: '44px',
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
    border: '1px solid #d9d9d9',
};

const filterSectionStyles = {
    backgroundColor: '#fff',
    padding: '16px',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.06)',
    marginBottom: '24px',
};

const activeFilterTagStyles = {
    padding: '6px 12px',
    borderRadius: '6px',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
};
