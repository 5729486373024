/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Col, Input, Modal, Select, Tag } from 'antd';
import { queryClient } from 'api';
import { useCreateRaffleMutation } from 'api/mutations/useCreateRaffleMutation';
import { queryKeys } from 'api/queryKeys';
import { type RaffleType } from 'api/types';
import { useNotification } from 'hooks';
import type { ModalController } from 'hooks/useModalController';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';

type Props = {
    modalController: ModalController;
};

export const CreateRaffleModal = ({
    modalController,
}: Props): $MixedElement => {
    const createRaffleMutation = useCreateRaffleMutation();
    const [show, contextHolder] = useNotification();
    const [name, setName] = useState('');
    const [raffleType, setRaffleType] = useState<RaffleType>('id_based');
    const [links, setLinks] = useState('');

    const onCancel = (): void => {
        modalController.close();
    };

    console.log(raffleType);

    const separateLinks = useMemo((): Array<string> => {
        return links
            .split('\n')
            .map((link) => link.trim())
            .filter(Boolean);
    }, [links]);

    const onGenerate = async (): Promise<void> => {
        const createdRaffle = await createRaffleMutation.mutateAsync({
            name,
            links: separateLinks,
            type: raffleType,
        });
        if (createdRaffle != null) {
            show('Raffle Created Succesfully');
            modalController.close();
            queryClient.refetchQueries({
                queryKey: [queryKeys.RAFFLES],
            });
        } else {
            show('Raffle creation failed');
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="Create Raffle"
                centered
                maskClosable={false}
                okText="Create"
                open={modalController.isOpen}
                onOk={onGenerate}
                okButtonProps={{
                    loading: createRaffleMutation.isPending,
                    disabled: name.trim() === '' || separateLinks.length === 0,
                }}
                onCancel={onCancel}
                width={1200}
            >
                <Col className="space-y-4 mt-4">
                    <Input
                        addonBefore="Name"
                        value={name}
                        onChange={(e): void => {
                            setName(e.target.value);
                        }}
                        placeholder="Name of campaign"
                    />
                    <Select
                        placeholder="Raffle Type"
                        className="w-full"
                        value={raffleType}
                        options={[
                            {
                                label: 'ID Based',
                                value: 'id_based',
                            },
                            {
                                label: 'Ordered',
                                value: 'ordered',
                            },
                        ]}
                        onChange={(value): void => {
                            setRaffleType(value as RaffleType);
                        }}
                    />
                    <Input.TextArea
                        rows={4}
                        onChange={(e) => {
                            setLinks(e.target.value);
                        }}
                        placeholder="Links"
                    />
                </Col>
                <Col className="mt-2 space-y-2 flex flex-col items-center overflow-auto max-h-[300px]">
                    {separateLinks.map((link) => (
                        <Tag color="blue">{link}</Tag>
                    ))}
                </Col>
            </Modal>
        </>
    );
};
