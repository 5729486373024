/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Editor } from '@monaco-editor/react';
import { Row, Typography } from 'antd';
import { Layout, Sidebar } from 'components';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';

export const DevtoolsEmailParser = (): $MixedElement => {
    const [html, setHtml] = useState('');

    const parsedEmail = useMemo(() => {
        try {
            const parsedEmail = html
                .replaceAll('"', "'") // Escape double quotes
                .replaceAll('\n', ' ') // Replace newlines with spaces
                .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .trim(); // Trim leading and trailing spaces
            return JSON.stringify(
                {
                    email: parsedEmail,
                },
                null,
                2,
            );
        } catch {
            return JSON.stringify(
                {
                    email: 'Invalid html',
                },
                null,
                2,
            );
        }
    }, [html]);

    const editorOptions = {
        height: '75vh',
        width: '100%',
        options: {
            fontSize: 14,
        },
    };

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>Email Parser</Typography.Title>
                <Row>
                    <div className="w-[50%] flex-col">
                        <Typography.Title level={4} className="mb-4">
                            Email
                        </Typography.Title>
                        <Editor
                            {...editorOptions}
                            value={html}
                            language="html"
                            onChange={(e): void => {
                                if (e != null) {
                                    setHtml(e);
                                }
                            }}
                        />
                    </div>
                    <div className="w-[50%] flex-col">
                        <Editor
                            {...editorOptions}
                            value={parsedEmail}
                            onChange={(): void => undefined}
                            language="json"
                            options={{
                                ...editorOptions.options,
                                readOnly: true,
                            }}
                        />
                    </div>
                </Row>
            </Layout>
        </Row>
    );
};
