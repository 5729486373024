/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { QueryClient } from '@tanstack/react-query';
import type {
    AllTxsResponse,
    ApiTokenDto,
    CreateRaffleDto,
    CustomAxiosError,
    ExtraConditions,
    Gatekeeper,
    GatekeeperResponse,
    GenerateCodesRequestDto,
    OdosPricesDto,
    OnrampByDateDto,
    PaginatedRequestQuery,
    RaffleDto,
    RaffleResponse,
    ScheduleNotificationDto,
    ScheduleNotificationForAddressesDto,
    ScheduledNotificationResponse,
    TranslationResponseDto,
    TranslationsRequestDto,
    UserDataDto,
    UserStatisticsDto,
    UsersResponseDto,
    WaitlistCodesResponseDto,
    WaitlistResponseDto,
} from 'api/types';
import Axios from 'axios';
import type { AxiosResponse } from 'axios';
import { ACCESS_TOKEN } from 'const';
import { getSanitizedApiUrl } from 'utils';
import type { DexScreenerResponse } from 'utils/clagg/types';
import { addressToToken } from 'utils/dashboardV2/tokenRegistry';
import { Provider } from 'zksync-ethers';

export const PROVIDER = new Provider('https://mainnet.era.zksync.io');

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            gcTime: 0,
            staleTime: 0,
        },
    },
});

export const axios = Axios.create({
    baseURL: getSanitizedApiUrl(),
});

axios.interceptors.request.use(function (config) {
    config.headers.authorization = `Bearer ${localStorage.getItem(
        ACCESS_TOKEN,
    )}`;
    return config;
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error: CustomAxiosError) {
        if (error.response?.status === 401) {
            localStorage.removeItem(ACCESS_TOKEN);
            alert(
                'Your session is ended. Please make sure you saved your work somewhere and log in again.',
            );
        }
        return Promise.reject(error);
    },
);

export const apiGetTranslations = async (): Promise<
    AxiosResponse<Array<TranslationResponseDto>>
> => {
    return axios.get('/translations');
};

export const apiPutTranslation = async (
    requestDto: TranslationsRequestDto,
): Promise<AxiosResponse<TranslationResponseDto>> => {
    return axios.put('/translations', requestDto);
};

export const apiPostTranslation = async (
    requestDto: TranslationsRequestDto,
): Promise<AxiosResponse<TranslationResponseDto>> => {
    return axios.post('/translations', requestDto);
};

export const apiGetWaitlists = async (
    query: PaginatedRequestQuery,
): Promise<AxiosResponse<WaitlistResponseDto>> => {
    return axios.get('/waitlist', {
        params: query,
    });
};

export const apiGetUsers = async (
    query: PaginatedRequestQuery,
): Promise<AxiosResponse<UsersResponseDto>> => {
    return axios.get('/internal/users', {
        params: query,
    });
};

export const apiGetWaitlistCodes = async (
    query: PaginatedRequestQuery,
): Promise<AxiosResponse<WaitlistCodesResponseDto>> => {
    return axios.get('/internal/waitlist-codes', {
        params: query,
    });
};

export const apiGetUserStatistics = async (): Promise<
    AxiosResponse<UserStatisticsDto>
> => {
    return axios.get('/internal/users/statistics');
};

export const apiPostGenerateWLCode = async (
    generateCodesRequestDto: GenerateCodesRequestDto,
): Promise<AxiosResponse<void>> => {
    return axios.post('/waitlist/codes/generate', generateCodesRequestDto);
};

export const apiPostGenerateAccessCode = async (): Promise<
    AxiosResponse<{ code: string }>
> => {
    return axios.post('/waitlist/codes/internal/single');
};

export const apiPostScheduleNotifications = async (
    data: ScheduleNotificationDto,
): Promise<AxiosResponse<unknown>> => {
    return axios.post(`/internal/notifications`, data);
};

export const apiPostScheduleNotificationsForAddresses = async (
    data: ScheduleNotificationForAddressesDto,
): Promise<AxiosResponse<unknown>> => {
    return axios.post(`/internal/notifications/addresses`, data);
};

export const apiDeleteScheduledNotifications = async (): Promise<
    AxiosResponse<unknown>
> => {
    return axios.delete(`/internal/notifications`);
};

export const apiGetScheduledNotifications = async (): Promise<
    AxiosResponse<Array<ScheduledNotificationResponse>>
> => {
    return axios.get(`/internal/notifications`);
};

export const apiGetRaffles = async (
    page: number,
): Promise<AxiosResponse<RaffleResponse>> => {
    return axios.get(`/internal/raffle`, {
        params: {
            page,
        },
    });
};

export const apiGetGatekeepers = async (): Promise<
    AxiosResponse<GatekeeperResponse>
> => {
    return axios.get(`/gatekeeper`);
};

export const apiGetGatekeeperByName = async (
    name: string,
): Promise<AxiosResponse<Gatekeeper>> => {
    return axios.get(`/gatekeeper/${name}`);
};

export const apiPutGatekeeper = async (
    gk: Partial<Gatekeeper>,
): Promise<AxiosResponse<Gatekeeper>> => {
    return axios.put(`/gatekeeper`, gk);
};

export const apiPostGatekeeper = async (
    gk: Partial<Gatekeeper>,
): Promise<AxiosResponse<Gatekeeper>> => {
    return axios.post(`/gatekeeper`, gk);
};

export const apiCreateRaffle = async (
    dto: CreateRaffleDto,
): Promise<AxiosResponse<RaffleDto>> => {
    return axios.post(`/internal/raffle`, dto);
};

export const apiGetOnrampStatsByDate = async (): Promise<
    AxiosResponse<Array<OnrampByDateDto>>
> => {
    return axios.get(`/internal/onramp-stats-by-date`);
};

export const apiGetAllStats = async (): Promise<
    AxiosResponse<Record<string, UserDataDto>>
> => {
    return axios.get(`/internal/all-stats`);
};

export const apiGetAllTxs = async (
    page: number,
    limit: number,
    address?: string,
    tables?: Array<string>,
    startDate?: number,
    endDate?: number,
    extraConditions?: ExtraConditions,
): Promise<AxiosResponse<AllTxsResponse>> => {
    const params: Record<string, string | number | object> = {
        page,
        limit,
    };

    if (address) {
        params.address = address;
    }

    if (tables?.length) {
        params.includedTables = tables.join(',');
    }

    if (startDate) {
        params.startDate = startDate;
    }

    if (endDate) {
        params.endDate = endDate;
    }

    if (extraConditions) {
        params.extraConditions = extraConditions;
    }

    return axios.get('/internal/users/transactions', { params });
};

export const apiGetTokens = async (): Promise<
    AxiosResponse<Array<ApiTokenDto>>
> => {
    return axios.get(`/token/list`);
};

const odos = Axios.create({
    baseURL: 'https://api.odos.xyz',
});

export const apiGetTokenPrices = async (): Promise<
    AxiosResponse<OdosPricesDto>
> => {
    const tokenAddresses =
        Object.keys(addressToToken).join('&token_addresses=');
    return odos.get(`/pricing/token/324?token_addresses=${tokenAddresses}`);
};

export const apiGetEarnConfig = async (): Promise<AxiosResponse<string>> => {
    return axios.get('/internal/invest-config');
};

export const apiPostEarnConfig = async (data: {
    config: string;
}): Promise<AxiosResponse<string>> => {
    return axios.post('/internal/invest-config', data);
};

const dexScreener = Axios.create({
    baseURL: 'https://api.dexscreener.com/latest',
});

export const apiGetDexScreener = async (
    pairAddress: string,
): Promise<AxiosResponse<DexScreenerResponse>> => {
    const options = {
        method: 'GET',
        url: `${dexScreener.defaults.baseURL}/dex/pairs/zksync/${pairAddress}`,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return axios.request<DexScreenerResponse>(options);
};

export { useGetTokenPricesQuery } from 'api/query/useGetTokenPrices';
export { useStatsQuery } from 'api/query/graph/useStatsQuery';
export { useAllSwapsQuery } from 'api/query/graph/useAllSwapsQuery';
export { useAllUsersStatsQuery } from 'api/query/graph/useAllUsersStatsQuery';
export { useIsUpgradedQuery } from 'api/query/useIsUpgradedQuery';
export { useGetAllStatsQuery } from './query/useGetAllStatsQuery';
export { useGetAllTxsQuery } from 'api/query/useGetAllTxsQuery';
export { useGetTokensQuery } from 'api/query/useGetTokensQuery';
export { useClaggTxs } from 'api/query/envio/useClaggTxs';
export { useUsdPerShare } from 'api/query/useUsdPerShare';
export { useClaggShares } from 'api/query/envio/useClaggShares';
export { useIdleBalances } from 'api/query/envio/useIdleBalances';
